import PropTypes from 'prop-types'

import { Box, Flex, Link, Typography, styled } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { PAYMENT_METHODS } from '@shared/constants'

import Bullets from './Bullets'
import CardElementWrapper from './CardElementWrapper'
import PaymentMethodsDropdown from './PaymentMethodsDropdown'

const PurchasePaymentMethodView = ({
  paymentMethod,
  tinted,
  onChange,
  isBusy,
  ...props
}) => (
  <Box {...props}>
    <PaymentMethodsDropdown mb={2} disabled={true} value={paymentMethod.type} />

    <Box>
      <Typography variant='inputLabel' width='fit-content'>
        <T
          label={
            paymentMethod.type === PAYMENT_METHODS.card
              ? 'label.paymentMethod'
              : 'label.iban'
          }
        />
      </Typography>
      <CardElementWrapper mt={1} tinted={tinted}>
        <Flex justifyContent='space-between' alignItems='center'>
          {paymentMethod.type === PAYMENT_METHODS.card && (
            <>
              <Typography
                variant='base14Bold'
                letterSpacing={1}
                color={Bullets.defaultColor}
              >
                <Bullets />
                <Bullets />
                <Bullets />
                {paymentMethod.lastFour}
              </Typography>
              <Typography
                variant='base14Bold'
                letterSpacing={1}
                color={Bullets.defaultColor}
              >{`${paymentMethod.expirationMonth}/${
                paymentMethod.expirationYear - 2000
              }`}</Typography>
            </>
          )}

          {paymentMethod.type === PAYMENT_METHODS.sepa && (
            <Typography
              variant='base14Light'
              color={Bullets.defaultColor}
              letterSpacing={3}
            >
              <Bullets />
              <Bullets />
              <Bullets />
              {paymentMethod.lastFour}
            </Typography>
          )}

          <Typography variant='labelButton'>
            <StyledLink
              onClick={onChange}
              busy={isBusy}
              data-testid='purchase-pm-change'
            >
              <T label='label.change' />
            </StyledLink>
          </Typography>
        </Flex>
      </CardElementWrapper>
    </Box>
  </Box>
)

const StyledLink = styled(Link)`
  cursor: pointer;
`

PurchasePaymentMethodView.propTypes = {
  tinted: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    type: PropTypes.string,
    lastFour: PropTypes.string,
    expirationMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    expirationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  onChange: PropTypes.func,
  isBusy: PropTypes.bool
}

export default PurchasePaymentMethodView
