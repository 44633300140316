import { useContext, useMemo } from 'react'

import { Dropdown } from '@etvas/etvaskit'

import { PAYMENT_METHODS } from '@shared/constants'
import { useFeatureFlags } from '@shared/hooks/useFeatureFlags'
import { I18nContext, T } from '@shared/i18n'

const PaymentMethodsDropdown = props => {
  const { translate } = useContext(I18nContext)
  const { allowedPaymentMethods } = useFeatureFlags()

  const methods = useMemo(
    () =>
      ALL_METHODS.filter(method =>
        allowedPaymentMethods.includes(method.value)
      ),
    [allowedPaymentMethods]
  )

  return (
    <Dropdown
      noBottomSpace
      data-testid='payment-method-dropdown'
      valueRender={value => selectedMethodLabel(value, translate)}
      label={<T label='label.paymentMethod' />}
      {...props}
      required>
      {methods.map(method => (
        <Dropdown.Option
          key={method.value}
          value={method.value}
          data-testid={`option.paymentMethod.${method.value}`}>
          <T label={method.i18nLabel} />
        </Dropdown.Option>
      ))}
    </Dropdown>
  )
}

const selectedMethodLabel = (method, translate) => {
  const { i18nLabel } = ALL_METHODS.find(({ value }) => value === method) ?? {}
  return translate(i18nLabel)
}

const ALL_METHODS = [
  { value: PAYMENT_METHODS.sepa, i18nLabel: 'label.directTransfer' },
  { value: PAYMENT_METHODS.card, i18nLabel: 'label.creditCard' }
]

export default PaymentMethodsDropdown
