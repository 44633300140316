/* eslint-disable camelcase */
import { useContext, useMemo, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

import { Button, Flex, Grid, Typography } from '@etvas/etvaskit'

import {
  CardElementWrapper,
  ModalDialog,
  SepaMandate
} from '@shared/components'
import { useBoolean } from '@shared/hooks'
import { I18nContext, T } from '@shared/i18n'

import { style } from '../paymentMethodUtils'
import PaymentMethodEmpty from './PaymentMethodEmpty'
import PaymentMethodView from './PaymentMethodView'

const SepaEditor = ({
  paymentMethodsResult,
  paymentMethod,
  showAddForm,
  setShowAddForm
}) => {
  const { language } = useContext(I18nContext)
  const { currentUser } = useContext(AuthContext)
  const { requestAddSetupIntent, isAddingPaymentMethod, forceUpdateCache } =
    paymentMethodsResult
  const stripe = useStripe()
  const elements = useElements()
  const [stripeReady, setStripeReady] = useState(false)
  const [isTokenizerBusy, setTokenizerBusy] = useState(false)
  const [intentError, setIntentError] = useState(null)
  const [stripeErrors, setStripeErrors] = useState({
    iban: true
  })
  const [stripeInputError, setStripeInputError] = useState()
  const [isMandateShown, openMandate, closeMandate] = useBoolean()

  if (elements) {
    elements.update({ locale: language })
  }

  const ibanElementOptions = {
    supportedCountries: ['SEPA'],
    style
  }

  const _resetStripeForm = resetInput => {
    setTokenizerBusy(false)
    if (resetInput) {
      setStripeErrors({ iban: true })
      if (elements) {
        const instance = elements.getElement(IbanElement)
        if (instance) {
          instance.clear()
        }
      }
    }
  }

  const _toggleAddForm = () => {
    _resetStripeForm()
    setIntentError(null)
    if (!showAddForm) {
      setStripeReady(false)
    }
    setShowAddForm(!showAddForm)
  }

  const _onIbanElementReady = e => {
    if (e._componentName === 'iban') {
      setStripeReady(true)
      setStripeErrors({ iban: true })
    }
  }

  const _onIbanElementChange = (name, event) => {
    const errors = { ...stripeErrors }
    errors[name] = !event.complete

    setStripeErrors(errors)
    if (event?.error?.message) {
      setStripeInputError(event.error.message)
      return
    }
    setStripeInputError()
  }

  const _onAddPaymentIntent = async () => {
    if (!stripe || !elements) {
      return
    }
    setIntentError('waitingForPayGateway')
    setTokenizerBusy(true)
    // CREATE STRIPE PAYMENT METHOD
    const result = await stripe.createPaymentMethod({
      type: 'sepa_debit',
      sepa_debit: elements.getElement(IbanElement),
      billing_details: {
        email: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        phone: currentUser.phoneNumber
      }
    })
    if (result.error) {
      console.error('* Error caught on stripe', result)
      setIntentError('stripeError')
      return false
    }

    // ATTACH PAYMENT METHOD MUTATION
    const {
      success,
      paymentMethod: newPaymentMethod,
      setupIntent
    } = await requestAddSetupIntent(result.paymentMethod.id)
    if (!success) {
      setIntentError('stripeError')
      return
    }
    if (setupIntent.status === 'succeeded') {
      setShowAddForm(false)
      setIntentError(null)
      return
    } else if (
      setupIntent.status !== 'requires_action' &&
      setupIntent.status !== 'requires_confirmation'
    ) {
      console.error('* Error processing setup intent status', setupIntent)
      setIntentError('stripeError')
      return
    }

    // CONFIRM SEPA SETUP
    // requires_action || requires_confirmation
    const confirmResponse = await stripe.confirmSepaDebitSetup(
      setupIntent.clientSecret,
      // eslint-disable-next-line camelcase
      { payment_method: newPaymentMethod.id }
    )
    if (confirmResponse.error) {
      _resetStripeForm(true)
      setIntentError('sepaFailed')
      return
    }

    forceUpdateCache(newPaymentMethod)
    setIntentError(null)
    setTokenizerBusy(false)
    setShowAddForm(false)
  }

  const _canSubmitStripe = useMemo(
    () =>
      !!stripe &&
      !!elements &&
      stripeReady &&
      Object.keys(stripeErrors).every(key => !stripeErrors[key]),
    [elements, stripe, stripeErrors, stripeReady]
  )

  if (!showAddForm) {
    if (paymentMethod) {
      return (
        <PaymentMethodView
          paymentMethod={paymentMethod}
          handleClick={_toggleAddForm}
        />
      )
    }
    return <PaymentMethodEmpty handleClick={_toggleAddForm} />
  }

  return (
    <>
      {isMandateShown && (
        <ModalDialog
          onDismiss={closeMandate}
          data-testid='mandate-modal'
          p={6}
          width={['90%', '400px']}
        >
          <SepaMandate
            confirm={() => {
              closeMandate()
              _onAddPaymentIntent()
            }}
          />
        </ModalDialog>
      )}

      <Grid vspace='0' cols={2}>
        <Grid.Item span={2}>
          <Typography variant='inputLabel'>
            <T label='label.iban' />
          </Typography>
          <CardElementWrapper mb={5} active>
            <IbanElement
              onReady={_onIbanElementReady}
              onChange={event => _onIbanElementChange('iban', event)}
              options={ibanElementOptions}
            />
          </CardElementWrapper>
        </Grid.Item>

        <Grid.Item span={2}>
          <Flex justifyContent='space-between' py={4}>
            {stripeInputError && (
              <Typography variant='base14Light' color='statusError' pr={2}>
                {stripeInputError}
              </Typography>
            )}
            {intentError ? (
              <Typography
                variant='labelButton'
                textAlign='center'
                fontWeight='lighter'
              >
                <T label={`text.${intentError}`} />
              </Typography>
            ) : null}
            <Flex alignItems='center' ml='auto'>
              <Button
                variant='link'
                width={{ _: '100%', md: 'auto' }}
                mr={4}
                onClick={_toggleAddForm}
                disabled={isTokenizerBusy || isAddingPaymentMethod}
                type='button'
              >
                <T label='label.cancel' />
              </Button>
              <Button
                variant='link'
                width={{ _: '100%', md: 'auto' }}
                ml={1}
                onClick={openMandate}
                disabled={
                  isTokenizerBusy || !_canSubmitStripe || isAddingPaymentMethod
                }
                type='button'
              >
                <T label='label.saveChanges' />
              </Button>
            </Flex>
          </Flex>
        </Grid.Item>
      </Grid>
    </>
  )
}

SepaEditor.propTypes = {
  paymentMethodsResult: PropTypes.object,
  paymentMethod: PropTypes.object,
  showAddForm: PropTypes.bool,
  setShowAddForm: PropTypes.func
}

export default SepaEditor
