import { useContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Flex, Form, TextField, Typography } from '@etvas/etvaskit'

import { ModalDialog } from '@shared/components'
import { assertConfirmed, userApi } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'

import { useProfile } from '../services'
import ConfirmationText from './ConfirmationText'

const ProfileDeleteModal = ({ closeModal, ...props }) => {
  const { translate } = useContext(I18nContext)
  const { isDeletingProfile, requestDeleteProfile } = useProfile()
  const [isSuccess, setSuccess] = useState(false)
  useEffect(() => {
    setSuccess(false)
  }, [])
  const initialValues = { confirmMessage: '' }

  const _handleSubmit = async (_, { setStatus }) => {
    setStatus(null)
    const success = await requestDeleteProfile()
    if (success) {
      userApi.softSignOut()
      setSuccess(true)
      return
    }
    setStatus('text.profileDeleteError')
  }

  const _handleAccountDeleted = async () => {
    await userApi.signOut()
  }

  const handleDismiss = () =>
    !isDeletingProfile
      ? isSuccess
        ? _handleAccountDeleted
        : closeModal
      : closeModal

  return (
    <ModalDialog
      title={
        <T
          label={
            isSuccess ? 'title.profileDeleted' : 'title.profileDeleteConfirm'
          }
        />
      }
      width={['90%', '510px']}
      p={10}
      onDismiss={handleDismiss()}
      {...props}
    >
      {isSuccess ? (
        <Flex flexDirection='column'>
          <Typography pt={6} variant='labelSmall'>
            <T label='text.profileDeleted' />
          </Typography>
          <Flex mt={8} alignItems='center' justifyContent='center'>
            <Button
              mx='auto'
              onClick={_handleAccountDeleted}
              variant='primary'
              type='submit'
              data-testid='deleted-account-confirmation'
            >
              <T label='label.ok' />
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection='column'>
          <Typography
            pt={6}
            pb={4}
            variant='labelSmall'
            dangerouslySetInnerHTML={{
              __html: translate('text.profileDeleteConfirm')
            }}
          ></Typography>
          <ConfirmationText variant='labelSmall' pb={5}>
            <T label='text.deleteAccountInfo' />
          </ConfirmationText>
          <Form initialValues={initialValues} onSubmit={_handleSubmit}>
            {({ status, values }) => (
              <Flex
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <TextField
                  name='confirmMessage'
                  id='confirmMessage'
                  tinted
                  disabled={isDeletingProfile}
                  placeholder={translate('text.typeHere')}
                  required
                />
                {status && (
                  <Typography my={2} variant='textSmall' color='error'>
                    {translate(status)}
                  </Typography>
                )}
                <Flex mt={4}>
                  <Button
                    variant='primary'
                    disabled={isDeletingProfile}
                    onClick={closeModal}
                  >
                    <T label='label.profileKeep' />
                  </Button>
                  <Button
                    data-testid='delete-profile-button'
                    variant='primary'
                    type='submit'
                    ml={3}
                    loading={isDeletingProfile}
                    disabled={
                      isDeletingProfile ||
                      !assertConfirmed(
                        values.confirmMessage,
                        translate('label.delete')
                      )
                    }
                  >
                    <T label='label.buttonDeleteProfile' />
                  </Button>
                </Flex>
              </Flex>
            )}
          </Form>
        </Flex>
      )}
    </ModalDialog>
  )
}

ProfileDeleteModal.propTypes = {
  closeModal: PropTypes.func
}

export default ProfileDeleteModal
