import { useCallback, useContext, useMemo } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import { useField } from 'formik'
import PropTypes from 'prop-types'

import { Dropdown } from '@etvas/etvaskit'

import { I18nContext, T } from '@shared/i18n'

export const LanguageSelector = ({ onChange, value, ...props }) => {
  const {
    organization: { languages: availableLanguages }
  } = useContext(OrganizationContext)

  const { languages, translate } = useContext(I18nContext)
  const handleChange = useCallback(v => onChange && onChange(v), [onChange])

  const displayValue = useMemo(() => {
    const lang = languages.find(({ id }) => id === value)
    return lang ? translate(`language.${lang.id}`) : ''
  }, [languages, translate, value])

  const isLanguageAvailable = ({ id }) => availableLanguages.includes(id)
  const _languages = languages.filter(isLanguageAvailable)

  return (
    <Dropdown onChange={handleChange} value={displayValue} {...props}>
      {_languages.map(({ id }) => (
        <Dropdown.Option key={id} value={id}>
          <T label={`language.${id}`} />
        </Dropdown.Option>
      ))}
    </Dropdown>
  )
}

LanguageSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
}

export const LanguageField = ({ liveChange, ...props }) => {
  // eslint-disable-next-line
  const [field, _, { setValue }] = useField(props)

  const handleChange = useCallback(
    value => {
      setValue(value)
      if (liveChange) {
        liveChange(value)
      }
    },
    [setValue, liveChange]
  )

  return (
    <LanguageSelector
      {...props}
      onChange={handleChange}
      value={field.value || ''}
      name={field.name}
      data-testid='language-dropdown'
    />
  )
}

LanguageField.propTypes = {
  liveChange: PropTypes.func
}
