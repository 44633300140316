import { useLayoutEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Button, Card, Icon, Touchable, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { Markdown } from '@shared/components'

const ProductDescription = ({ product, ...props }) => {
  const [isModalShown, setModalShown] = useState(false)

  const _toggleModal = () => {
    setModalShown(!isModalShown)
  }

  return (
    <Box {...props}>
      <Typography variant='labelButton' color='outline' mb={1}>
        <T label='text.productDescription' />
      </Typography>

      <Card borderRadius='8px' p={6} pb={1}>
        <Markdown variant='title20Light' md={product.description} />
        <Box my={6}>
          <Button
            variant='link'
            onClick={_toggleModal}
            data-testid='product-tc'
          >
            <T label='label.termsAndConditions' />
          </Button>
        </Box>
      </Card>
      {isModalShown ? (
        <ModalWrapper>
          <FatModal>
            <IconBtn onClick={_toggleModal} data-testid='close-tc'>
              <Icon size={'large'} name='close' />
            </IconBtn>
            <FatModalContainer>
              <Markdown variant='title20Light' md={product.terms} />
            </FatModalContainer>
          </FatModal>
        </ModalWrapper>
      ) : null}
    </Box>
  )
}

export const ModalWrapper = ({ children }) => {
  useLayoutEffect(() => {
    disableScroll()

    return enableScroll
  }, [])

  return children
}

export const disableScroll = () => (document.body.style.overflow = 'hidden')
export const enableScroll = () => (document.body.style.overflow = 'auto')

const IconBtn = styled(Touchable)`
  position: absolute;
  padding: 4px 8px;
  right: 10px;
  top: 10px;
  font-size: 21px;
  z-index: 100;
`

const FatModal = styled.div`
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: white;
  position: fixed;
`

const FatModalContainer = styled.div`
  position: absolute;
  background: white;
  left: 32px;
  top: 50px;
  right: 32px;
  bottom: 0px;
  padding: 0px 24px 24px 24px;
  overflow-y: auto;
  @media (min-width: 960px) {
    left: 50%;
    right: auto;
    width: 800px;
    margin-left: -400px;
  }
`

ProductDescription.propTypes = {
  product: PropTypes.object
}

export default ProductDescription
