import { useContext, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Button, Card, Flex, Link, Typography } from '@etvas/etvaskit'

import { I18nContext, T } from '@shared/i18n'

import { CustomerLinks } from './CustomerLinks'

const ImprintCard = ({ hideTitle }) => {
  const [seeMore, setSeeMore] = useState(false)

  const { translate } = useContext(I18nContext)

  const contactEmail = translate('label.email.contact')
  return (
    <Card position='relative' px={8} py={6} data-testid='imprint-card'>
      {!hideTitle && (
        <Typography variant='base12Bold' color='lightGray'>
          <T label='label.imprint' />
        </Typography>
      )}

      <Flex flexWrap='wrap' alignItems='center' my={5}>
        <Info
          label={<T label='label.address' />}
          text={<T label='label.address.contact' />}
          mr={12}
        />
        <Info
          label={<T label='label.phone' />}
          text={<T label='label.phone.contact' />}
          mr={12}
        />
        <Info
          label={<T label='label.email' />}
          link={
            <MailTo component='a' href={`mailto:${contactEmail}`}>
              {contactEmail}
            </MailTo>
          }
        />
        <SeeMoreBtn
          ml='auto'
          variant='link'
          onClick={() => setSeeMore(seeMore => !seeMore)}>
          <T label={!seeMore ? 'label.seeMore' : 'label.seeLess'} />
        </SeeMoreBtn>
      </Flex>
      {seeMore && <MoreDetails />}
    </Card>
  )
}

ImprintCard.propTypes = {
  hideTitle: PropTypes.bool
}

ImprintCard.defaultProps = {
  hideTitle: false
}

const MoreDetails = () => {
  const { translate } = useContext(I18nContext)

  const euConsumerLink = translate('label.euConsumerLink')

  return (
    <>
      <Info
        label={<T label='label.represent' />}
        text={<T label='label.name.contact' />}
        mb={4}
      />
      <Info
        label={<T label='label.registerCourt' />}
        text={<T label='text.registerCourt' />}
        mb={4}
      />
      <Info
        label={<T label='label.notice' />}
        text={<T label='text.notice' />}
        mb={4}
      />

      <Info
        label={<T label='label.responsible' />}
        text={<T label='text.responsible' />}
        mb={4}
      />
      <Info
        label={<T label='label.platform' />}
        text={<T label='text.platform' />}
        link={
          <Link href={euConsumerLink} rel='noopener noreferrer' target='_blank'>
            {euConsumerLink}
          </Link>
        }
        mb={4}
      />
      <CustomerLinks label='text.and' />
    </>
  )
}

const Info = ({ label, text, link, ...props }) => (
  <Box {...props}>
    <InlineLabel variant='base12Bold' color='lightGray'>
      {label && <>{label}: </>}
    </InlineLabel>
    <InlineText>
      {text}
      {link}
    </InlineText>
  </Box>
)

Info.propTypes = {
  label: PropTypes.node,
  text: PropTypes.node,
  link: PropTypes.any,
  mr: PropTypes.number,
  mb: PropTypes.number
}

const SeeMoreBtn = styled(Button)`
  position: absolute;
  right: 16px;
  bottom: 16px;
`

const MailTo = styled(Link)`
  color: unset;
`

const InlineText = styled(Typography)`
  display: inline;
`

const InlineLabel = styled(Typography)`
  display: inline;
  text-transform: uppercase;
`

export default ImprintCard
