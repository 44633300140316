import PropTypes from 'prop-types'
import styled from 'styled-components'

import { themed } from '@etvas/etvaskit'

import { LanguageSwitcher } from '@shared/components'
import { cssStyleVar, getRandomInt, isImage } from '@shared/funcs'

const AuthTemplate = ({ children, brandMedia }) => {
  const bgMedia = brandMedia
    ? brandMedia[getRandomInt(brandMedia.length)]
    : cssStyleVar.read('brand-image')

  return (
    <AuthWrapper bgImage={bgMedia} id='auth-wrapper'>
      {!isImage(bgMedia) && (
        <BackgroundVideo src={bgMedia} loop muted autoPlay />
      )}

      <LanguageSwitcherWrapper>
        <LanguageSwitcher data-testid='auth-language-dropdown' />
      </LanguageSwitcherWrapper>

      {children}
    </AuthWrapper>
  )
}

const AuthWrapper = styled.div`
  ${themed('AuthenticationTemplate.wrapper')};
  background-image: url(${({ bgImage }) => bgImage});
`

const BackgroundVideo = styled.video`
  ${themed('AuthenticationTemplate.bgVideo')};
`

const LanguageSwitcherWrapper = styled.span`
  ${themed('AuthenticationTemplate.languageSwitcher')};
`

AuthTemplate.propTypes = {
  brandMedia: PropTypes.array,
  children: PropTypes.node
}

export default AuthTemplate
