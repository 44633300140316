import { forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Card, Flex, Image, Typography, etvasTheme } from '@etvas/etvaskit'

import { ChargeText, PriceOption, TrialChip } from '@shared/components'
import { useFeatureGate, useMatchWidth } from '@shared/hooks'

import { isProductPurchased } from '../hooks/purchasedService'
import ProductOverviewSkeleton from './ProductOverviewSkeleton'
import PurchaseButton from './PurchaseButton'

const ProductOverview = forwardRef(
  ({ authenticated, handlePurchase, product, loading }, ref) => {
    const isDesktop = useMatchWidth(etvasTheme.breakpoints.md)

    const isNewLandingPage = useFeatureGate('full_landing_test')

    const textCardPosition = isNewLandingPage ? 'hidden' : 'below'
    const trialChipPosition = isNewLandingPage ? 'over' : 'under'
    const spLogoOrName = isNewLandingPage ? 'name' : 'logo'
    const trialChargeBackground = isNewLandingPage
      ? 'etvasLightest'
      : 'transparent'
    const productImagePosition = 'right'

    const isPurchased =
      authenticated && !loading ? isProductPurchased(product) : false

    const isTrial = product && !!product.trialPeriod

    const mobileFlexDirection = useMemo(
      () => (textCardPosition === 'above' ? 'column' : 'column-reverse'),
      [textCardPosition]
    )

    const hideImage = useMemo(
      () => !isDesktop && textCardPosition === 'hidden',
      [textCardPosition, isDesktop]
    )

    const desktopFlexDirection = useMemo(
      () => (productImagePosition === 'right' ? 'row' : 'row-reverse'),
      [productImagePosition]
    )

    if (loading) {
      return (
        <ProductOverviewSkeleton
          mobileFlexDirection={mobileFlexDirection}
          hideImage={hideImage}
          desktopFlexDirection={desktopFlexDirection}
        />
      )
    }

    return (
      <div ref={ref}>
        <Card data-testid='landing-overview'>
          <Flex
            flexDirection={[
              mobileFlexDirection,
              mobileFlexDirection,
              desktopFlexDirection
            ]}
            justifyContent='space-between'
            maxWidth='1200px'
            margin='0 auto'>
            <Flex
              p={[6, 6, 6, 6, 0]}
              flexDirection='column'
              justifyContent='space-between'
              my={4}>
              <Box mb={4}>
                {!loading && trialChipPosition === 'under' && (
                  <TrialChip product={product} size='large' mb={4} isNeutral />
                )}
                {spLogoOrName === 'logo' && (
                  <Image src={product?.servicePartner?.logo} width='112px' />
                )}
                <HeroTypography
                  mt={spLogoOrName === 'logo' ? 4 : 0}
                  data-testid='product-name'>
                  {product?.name}
                </HeroTypography>
                {spLogoOrName === 'name' && (
                  <Typography variant='base16Light' mt={2} color='baseGrayDark'>
                    {product?.servicePartner?.name}
                  </Typography>
                )}
                <Typography
                  variant='labelLarge'
                  mt={4}
                  data-testid='product-summary'>
                  {product?.summary}
                </Typography>
              </Box>
              <Box>
                {!isPurchased && !isTrial && (
                  <Typography variant='labelLarge' color='accent' mt={4}>
                    {product ? <PriceOption product={product} /> : null}
                  </Typography>
                )}
                <PurchaseButton
                  onPurchase={handlePurchase}
                  product={product}
                  loading={loading}
                  variant='primary'
                  height={56}
                  px={6}
                  mt={4}
                  mb={4}
                  data-testid='product-primary-purchase'
                />
                {!loading && !isPurchased && (
                  <Box p={isNewLandingPage ? 4 : 0} bg={trialChargeBackground}>
                    <ChargeText
                      onlyWithTrial
                      product={product}
                      variant='textSmall'
                    />
                  </Box>
                )}
              </Box>
            </Flex>
            {product && !hideImage && (
              <Flex
                flexDirection='row'
                alignItems='center'
                justifyContent='center'>
                <StyledBox>
                  <Image
                    src={product.imageURL}
                    alt={product.name}
                    width={1}
                    maxHeight='600px'
                  />
                  {trialChipPosition === 'over' && (
                    <TrialChip
                      product={product}
                      size='large'
                      width='max-content'
                      height='max-content'
                      position='absolute'
                      right={6}
                      bottom={6}
                    />
                  )}
                </StyledBox>
              </Flex>
            )}
          </Flex>
        </Card>
      </div>
    )
  }
)

const HeroTypography = styled(Typography)`
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  @media (min-width: 640px) {
    font-size: 40px;
    line-height: 56px;
  }
  @media (min-width: 1024px) {
    font-size: 56px;
    line-height: 64px;
  }
`

const StyledBox = styled(Box)`
  width: max-content;
  height: max-content;
  position: relative;
  @media (min-width: 640px) {
    width: 320px;
  }
  @media (min-width: 960px) {
    width: 480px;
  }
`

ProductOverview.propTypes = {
  authenticated: PropTypes.bool,
  handlePurchase: PropTypes.func,
  product: PropTypes.object,
  loading: PropTypes.bool
}

export default ProductOverview
