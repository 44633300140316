import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'

import { Grid } from '@etvas/etvaskit'

import { ImprintCard, NavBack } from '@shared/components'
import { BillingInfoCard } from '@shared/components/BillingInfo'
import { T } from '@shared/i18n'

import AccountInformation from './AccountInformation'
import PaymentCoupon from './PaymentCoupon'
import PaymentHistory from './PaymentHistory'
import PurchasedProducts from './PurchasedProducts'
import PaymentMethodEditor from './components/PaymentMethodEditor'
import TitleCard from './components/TitleCard'

export const Account = () => {
  const { authenticated, currentUser } = useContext(AuthContext)

  if (!authenticated) {
    return '...'
  }

  return (
    <>
      <NavBack>
        <T label='menu.myAccount' />
      </NavBack>

      <Grid cols={2} vspace='0'>
        <Grid.Item>
          <>
            <TitleCard
              title='title.personalInformation'
              data-testid='account-information'
            >
              <AccountInformation user={currentUser} />
            </TitleCard>
            <TitleCard
              title='title.paymentInformation'
              data-testid='payment-method'
            >
              <PaymentMethodEditor />
            </TitleCard>
          </>
        </Grid.Item>

        <Grid.Item>
          <>
            <TitleCard
              title='title.billingInformation'
              data-testid='billing-information'
            >
              <BillingInfoCard showVat={true} />
            </TitleCard>

            <TitleCard title='title.couponCode' data-testid='coupon-editor'>
              <PaymentCoupon />
            </TitleCard>
          </>
        </Grid.Item>
      </Grid>

      <TitleCard title='title.myProducts' data-testid='purchased-products'>
        <PurchasedProducts />
      </TitleCard>
      <TitleCard title='title.paymentHistory' data-testid='payment-history'>
        <PaymentHistory />
      </TitleCard>
      <ImprintCard />
    </>
  )
}
