import PropTypes from 'prop-types'
import TrackVisibility from 'react-on-screen'

import { Grid } from '@etvas/etvaskit'

import { EmptyBox, ProductCard } from '@shared/components'
import { useMatchWidth } from '@shared/hooks'

import { AvailableProductsSkeleton } from '../../discover/components/DiscoverSkeleton'

export const PurchasedProducts = ({
  isFetching,
  products,
  noProducts,
  showSeeMore,
  showAction
}) => {
  const shouldNotWrap = useMatchWidth('880px')

  return isFetching && products.length === 0 ? (
    <AvailableProductsSkeleton />
  ) : products.length === 0 ? (
    <EmptyBox noProducts={noProducts} />
  ) : (
    <Grid
      cols={shouldNotWrap ? 2 : 1}
      hspace='1.5rem'
      vspace='1.5rem'
      data-testid='purchased-products'
    >
      {products.map((product, idx) => (
        <Grid.Item key={product.id} span={idx === 0 ? 2 : 1}>
          <TrackVisibility>
            <ProductCard
              variant={idx === 0 ? 'hero' : 'default'}
              product={product}
              data-testid={`product-${product.id}`}
              showSeeMore={showSeeMore}
              showAction={showAction}
              hideRating={true}
            />
          </TrackVisibility>
        </Grid.Item>
      ))}
    </Grid>
  )
}

PurchasedProducts.propTypes = {
  isFetching: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.object),
  noProducts: PropTypes.oneOf([true, 'link']),
  showSeeMore: PropTypes.oneOf([false, true, 'link', 'button']),
  showAction: PropTypes.oneOf([false, true, 'embed'])
}

PurchasedProducts.defaultProps = {
  noProducts: true,
  showSeeMore: true,
  showAction: true
}
