import { authTemplateLogoImageSize } from '@assets/styles/sizes'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Card, Flex, Typography } from '@etvas/etvaskit'

import { LogoImage } from '@shared/components'

import AuthCarousel from './AuthCarousel'

const RATIO = 1

const AuthenticationTemplate = ({ title, children }) => (
  <Scroll>
    <Flex
      alignItems='center'
      flexDirection={['column', 'column', 'column', 'row']}
    >
      <Card
        width={['90vw', '410px']}
        mb={['50px', '50px', '50px', '0px']}
        mx={[3, 3, 3, '40px', '100px']}
        p={[6, 6, 6, 12]}
      >
        <Flex
          alignItems='center'
          justifyContent='center'
          height={authTemplateLogoImageSize}
          mx='auto'
        >
          <LogoImage size='small' />
        </Flex>
        <Typography
          variant='titleLarge'
          my={4}
          textAlign='center'
          data-testid='form-title'
        >
          {title}
        </Typography>
        {children}
      </Card>
      <AuthCarousel autoAdvance={true} ratio={RATIO} />
    </Flex>
  </Scroll>
)

const Scroll = styled.div({
  width: '100%',
  padding: '40px 0',
  overflowY: 'scroll',
  '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
  'scrollbar-width': 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none' /* Safari and Chrome */
  }
})

AuthenticationTemplate.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node
}

export default AuthenticationTemplate
