import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'

import { Box, Flex, Typography } from '@etvas/etvaskit'

import { PriceOption } from '@shared/components'

import { isProductPurchased } from '../hooks/purchasedService'
import PurchaseButton from './PurchaseButton'

export const NavScroll = ({
  product,
  loading,
  authenticated,
  handlePurchase
}) => {
  const { organization } = useContext(OrganizationContext)
  const isWhite = organization?.landingHeaderColor?.toLowerCase() !== '#ffffff'

  return (
    <Box
      display={['none', 'none', 'block']}
      data-testid='purchase-nav-bar'
      bg={isWhite ? 'white' : 'brandDark'}
      p={4}
    >
      <Box display={['block', 'block', 'none']}>
        <ProductPurchase
          product={product}
          loading={loading}
          authenticated={authenticated}
          handlePurchase={handlePurchase}
        />
      </Box>
      <Box display={['none', 'none', 'block']} mx='auto' maxWidth='1200px'>
        <Flex justifyContent='space-between'>
          <ProductPurchase
            product={product}
            loading={loading}
            authenticated={authenticated}
            handlePurchase={handlePurchase}
          />
        </Flex>
      </Box>
    </Box>
  )
}

const ProductPurchase = ({
  product,
  loading,
  authenticated,
  handlePurchase
}) => {
  const { organization } = useContext(OrganizationContext)
  const isWhite = organization?.landingHeaderColor?.toLowerCase() !== '#ffffff'

  const isPurchased = isProductPurchased(product)

  return (
    <>
      <Typography
        variant='title24Light'
        mr={[0, 0, 8]}
        {...(!isWhite ? { color: 'white' } : {})}
        textAlign='center'
        truncate
      >
        {product?.name}
      </Typography>
      <Flex
        alignItems='center'
        justifyContent='center'
        flexDirection={['column', 'column', 'row']}
      >
        {!isPurchased && (
          <Typography
            variant='base16Light'
            {...(!isWhite ? { color: 'white' } : {})}
            mr={[0, 0, 4]}
            mb={[4, 4, 0]}
          >
            {product ? <PriceOption product={product} /> : null}
          </Typography>
        )}

        <PurchaseButton
          authenticated={authenticated}
          onPurchase={handlePurchase}
          product={product}
          loading={loading}
          variant='primary'
          data-testid='product-secondary-purchase'
        />
      </Flex>
    </>
  )
}

NavScroll.propTypes = {
  authenticated: PropTypes.bool,
  loading: PropTypes.bool,
  product: PropTypes.object,
  handlePurchase: PropTypes.func
}

ProductPurchase.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  handlePurchase: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string,
    servicePartner: PropTypes.object
  }).isRequired
}
