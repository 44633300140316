import { Fragment, useContext, useMemo } from 'react'

import { useAuthUser } from '@services/hooks'
import moment from 'moment'
import PropTypes from 'prop-types'
import TrackVisibility from 'react-on-screen'

import { Grid } from '@etvas/etvaskit'

import { OrganizationContext } from 'app/services/OrganizationProvider'

import { ProductCard, Section } from '@shared/components'
import { shuffleArray } from '@shared/funcs'
import { useMatchWidth } from '@shared/hooks'

import { CouponBanner } from './CouponBanner'
import { AvailableProductsSkeleton } from './DiscoverSkeleton'

export const AvailableProducts = ({
  isFetching,
  products: originalProducts,
  showSeeMore,
  seeMoreURLPrefix,
  showAction,
  noHero
}) => {
  const { cognitoUser } = useAuthUser()
  const { organization } = useContext(OrganizationContext)

  const shuffleSeed = cognitoUser?.id ?? moment().format('YYYY-MM-DD')
  const products = useMemo(
    () =>
      organization?.randomizeProductsOrder
        ? shuffleArray(originalProducts.slice(0), shuffleSeed)
        : originalProducts,
    [organization, originalProducts, shuffleSeed]
  )

  const shouldNotWrap = useMatchWidth('880px')
  const [hero, ...restOfProducts] = useMemo(
    () =>
      !isFetching && products.length
        ? noHero
          ? [null, ...products]
          : products
        : [],
    [isFetching, products, noHero]
  )

  return isFetching ? (
    <AvailableProductsSkeleton />
  ) : (
    <>
      {!!hero && (
        <Grid cols={shouldNotWrap ? 2 : 1}>
          <Grid.Item span={2}>
            <TrackVisibility>
              <ProductCard
                variant={shouldNotWrap ? 'hero' : 'default'}
                product={hero}
                data-testid={`product-${hero.id}`}
                showSeeMore={showSeeMore}
                seeMoreURLPrefix={seeMoreURLPrefix}
                showAction={showAction}
                hideRating={true}
              />
            </TrackVisibility>
          </Grid.Item>
          <Grid.Item span={2} mt={6}>
            <CouponBanner mb={5} />
          </Grid.Item>
        </Grid>
      )}

      <Section>
        <Grid
          cols={shouldNotWrap ? 2 : 1}
          hspace='1.5rem'
          vspace='1.5rem'
          data-testid='available-products'>
          {restOfProducts.map((product, idx) =>
            idx === 2 && shouldNotWrap ? (
              <Fragment key={product.id}>
                <Grid.Item mb={5} span={2}>
                  <Fragment />
                </Grid.Item>
                <Grid.Item key={product.id} span={1}>
                  <TrackVisibility>
                    <ProductCard
                      variant='default'
                      product={product}
                      data-testid={`product-${product.id}`}
                      showSeeMore={showSeeMore}
                      seeMoreURLPrefix={seeMoreURLPrefix}
                      showAction={showAction}
                      hideRating={true}
                    />
                  </TrackVisibility>
                </Grid.Item>
              </Fragment>
            ) : (
              <Grid.Item key={product.id} span={1}>
                <TrackVisibility>
                  <ProductCard
                    variant='default'
                    product={product}
                    data-testid={`product-${product.id}`}
                    showSeeMore={showSeeMore}
                    seeMoreURLPrefix={seeMoreURLPrefix}
                    showAction={showAction}
                    hideRating={true}
                  />
                </TrackVisibility>
              </Grid.Item>
            )
          )}
        </Grid>
      </Section>
    </>
  )
}

AvailableProducts.propTypes = {
  isFetching: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.object),
  showSeeMore: PropTypes.oneOf([true, false, 'link', 'button']),
  seeMoreURLPrefix: PropTypes.string,
  showAction: PropTypes.oneOf([false, true, 'embed']),
  noHero: PropTypes.bool
}

AvailableProducts.defaultProps = {
  showSeeMore: true,
  showAction: true,
  seeMoreURLPrefix: '',
  noHero: false
}
