import { useEffect, useMemo } from 'react'

import { breakpoints } from '@assets/styles/_utils'
import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Box,
  Button,
  CardWithImage,
  Flex,
  Icon,
  Link,
  Rating,
  Typography
} from '@etvas/etvaskit'

import { QueryPreserverLink, ServicePartnerLogo } from '@shared/components'
import { sendWindowMessage, slugify } from '@shared/funcs'
import { useMatchWidth } from '@shared/hooks'
import { T } from '@shared/i18n'
import { productShape, purchaseStatus } from '@shared/models'

import { ChargeText } from './ChargeText'
import { PriceOption } from './PriceOption'
import { PurchaseButton } from './PurchaseButton'
import { TrialChip } from './TrialChip'

export const ProductCard = ({
  variant,
  product,
  imageSize,
  imageContain,
  isVisible,
  boxSize,
  showSeeMore,
  seeMoreText,
  seeMoreURLPrefix,
  showAction,
  hideRating,
  noTrack,
  withTrialInfo,
  initPurchase,
  ...props
}) => {
  const { debounceTrack } = useTracking()

  const isHeroCard = variant === 'hero'
  const isMobile = !useMatchWidth(breakpoints.sm)

  const webBoxSize = isHeroCard ? '22rem' : '20.75rem'
  const mobileBoxSize = '35rem'

  const cardHeight = useMemo(
    () => (boxSize === 'small' ? 'unset' : [mobileBoxSize, webBoxSize]),
    [mobileBoxSize, webBoxSize, boxSize]
  )

  const defaultImageSize = isHeroCard ? [0.3, 0.4] : [0.4, 0.4, 0.3, 0.4]

  const productLink = `${seeMoreURLPrefix || `/products`}/${slugify(
    product.name
  )}/${product.id}`

  const isSuspended = purchaseStatus.canBeReactivated(product.purchaseStatus)
  const isPurchased = product.purchaseStatus === purchaseStatus.Purchased

  const sendLinkMessage = () =>
    sendWindowMessage('open-product-details', { productId: product.id })
  const seeMoreLabel = useMemo(
    () => seeMoreText || <T label='label.details' />,
    [seeMoreText]
  )

  useEffect(() => {
    if (isVisible && !noTrack) {
      debounceTrack(product.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, product.id, noTrack])

  return (
    <Flex height={cardHeight} {...props}>
      <CardWithImage
        imageUrl={product.imageURL}
        imageSize={imageSize || defaultImageSize}
        imageContain={imageContain}
        height='100%'
        width='100%'
        variant={variant}
        imgOnLeft={true}>
        <Flex flexDirection='column' height='100%' p={isHeroCard ? 0 : 2}>
          <Header product={product} hideRating={hideRating} />

          <TrialChip product={product} mb={1} />

          <Typography
            mb={2}
            truncate={2}
            variant={isHeroCard ? 'titleLarge' : 'labelLarge'}
            title={product.name}
            data-testid='product-title'>
            {product.name}
          </Typography>

          <Typography
            truncate={6}
            variant={isHeroCard ? 'textLarge' : 'textSmall'}
            data-testid='product-summary'
            mb={[2, 4]}>
            {product.summary}
          </Typography>

          {withTrialInfo && <ChargeText product={product} onlyWithTrial />}
          <Box mt='auto'>
            {isMobile ? (
              <MobileFooter
                product={product}
                productLink={productLink}
                isSuspended={isSuspended}
                isPurchased={isPurchased}
                sendLinkMessage={sendLinkMessage}
                seeMoreLabel={seeMoreLabel}
                seeMoreText={seeMoreText}
                isHeroCard={isHeroCard}
                initPurchase={initPurchase}
                showAction={showAction}
                showSeeMore={showSeeMore}
              />
            ) : (
              <DesktopFooter
                product={product}
                productLink={productLink}
                isSuspended={isSuspended}
                isPurchased={isPurchased}
                sendLinkMessage={sendLinkMessage}
                seeMoreLabel={seeMoreLabel}
                seeMoreText={seeMoreText}
                isHeroCard={isHeroCard}
                initPurchase={initPurchase}
                showAction={showAction}
                showSeeMore={showSeeMore}
              />
            )}
          </Box>
        </Flex>
      </CardWithImage>
    </Flex>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

const Header = ({ product, hideRating }) => {
  const shouldHideServicePartnerLogo =
    product.servicePartner.hideProductBranding

  if (shouldHideServicePartnerLogo && hideRating) {
    return null
  }

  return (
    <Flex mb={[3, 4]} alignItems='center' justifyContent='space-between'>
      {!shouldHideServicePartnerLogo && (
        <ServicePartnerLogo
          servicePartner={product.servicePartner}
          data-testid='product-splogo'
        />
      )}

      {!hideRating && (
        <Box data-testid='product-rating'>
          <Rating rating={product.rating} />
        </Box>
      )}
    </Flex>
  )
}

Header.propTypes = {
  product: productShape,
  hideRating: PropTypes.bool
}

const MobileFooter = ({
  product,
  showAction,
  showSeeMore,
  isHeroCard,
  initPurchase,
  isPurchased,
  isSuspended,
  productLink,
  seeMoreLabel,
  sendLinkMessage
}) => (
  <>
    <Flex
      justifyContent='space-between'
      alignItems='center'
      flexWrap='wrap'
      data-testid='product-purchase'>
      {showSeeMore === true && (
        <StyledLink
          component={QueryPreserverLink}
          to={productLink}
          variant='button'>
          <Icon name='bookOpenOutline' size='medium' color='brand' />
          <Box ml={2} mr={2}>
            {seeMoreLabel}
          </Box>
        </StyledLink>
      )}
      {showSeeMore === 'link' || showSeeMore === 'button' ? (
        <Button
          variant={showSeeMore === 'link' ? 'link' : 'primary'}
          icon='badgeAccountHorizontal'
          onClick={sendLinkMessage}
          mr={2}>
          {seeMoreLabel}
        </Button>
      ) : null}
      {showAction && (
        <Flex alignItems='center' justifyContent='flex-end'>
          <ProductPrice
            isSuspended={isSuspended}
            isPurchased={isPurchased}
            isHeroCard={isHeroCard}
            product={product}
          />
        </Flex>
      )}
    </Flex>
    {showAction && (
      <Flex justifyContent='flex-end' mt={2}>
        <PurchaseButton
          product={product}
          showEmbeddedUse={showAction === 'embed'}
          showEmbeddedPurchase={showAction === 'embed'}
          isHero={isHeroCard}
          initPurchase={initPurchase}
          width={[1, 'auto']}
        />
      </Flex>
    )}
  </>
)

MobileFooter.propTypes = {
  productLink: PropTypes.string,
  isSuspended: PropTypes.bool,
  isPurchased: PropTypes.bool,
  sendLinkMessage: PropTypes.func,
  seeMoreLabel: PropTypes.node,
  product: productShape,
  showSeeMore: PropTypes.oneOf([false, true, 'link', 'button']),
  showAction: PropTypes.oneOf([false, true, 'embed']),
  initPurchase: PropTypes.bool,
  isHeroCard: PropTypes.bool
}

const DesktopFooter = ({
  product,
  showAction,
  showSeeMore,
  isHeroCard,
  initPurchase,
  isPurchased,
  isSuspended,
  productLink,
  seeMoreLabel,
  sendLinkMessage
}) => (
  <>
    {showAction && (
      <Flex alignItems='center' justifyContent='flex-end'>
        <ProductPrice
          isSuspended={isSuspended}
          isPurchased={isPurchased}
          isHeroCard={isHeroCard}
          product={product}
        />
      </Flex>
    )}
    <Flex
      justifyContent={showSeeMore === false ? 'flex-end' : 'space-between'}
      alignItems='center'
      flexWrap='wrap'
      data-testid='product-purchase'>
      {showSeeMore === true && (
        <StyledLink
          component={QueryPreserverLink}
          to={productLink}
          variant='button'>
          <Icon name='bookOpenOutline' size='medium' color='brand' />
          <Box ml={2} mr={2}>
            {seeMoreLabel}
          </Box>
        </StyledLink>
      )}
      {showSeeMore === 'link' || showSeeMore === 'button' ? (
        <Button
          variant={showSeeMore === 'link' ? 'link' : 'primary'}
          icon='badgeAccountHorizontal'
          onClick={sendLinkMessage}
          mr={2}>
          {seeMoreLabel}
        </Button>
      ) : null}
      {showAction && (
        <PurchaseButton
          product={product}
          showEmbeddedUse={showAction === 'embed'}
          showEmbeddedPurchase={showAction === 'embed'}
          isHero={isHeroCard}
          initPurchase={initPurchase}
          width={[1, 'auto']}
        />
      )}
    </Flex>
  </>
)

DesktopFooter.propTypes = {
  productLink: PropTypes.string,
  isSuspended: PropTypes.bool,
  isPurchased: PropTypes.bool,
  sendLinkMessage: PropTypes.func,
  seeMoreLabel: PropTypes.node,
  product: productShape,
  showSeeMore: PropTypes.oneOf([false, true, 'link', 'button']),
  showAction: PropTypes.oneOf([false, true, 'embed']),
  initPurchase: PropTypes.bool,
  isHeroCard: PropTypes.bool
}

const ProductPrice = ({ isSuspended, isHeroCard, isPurchased, product }) => {
  if (isSuspended) {
    return (
      <>
        <Typography mr={1} color='error'>
          <T label='label.productSuspended' />
        </Typography>
        <Icon name='alertCircle' color='error' size='small' />
      </>
    )
  }
  if (!isHeroCard && !isPurchased) {
    return (
      <Typography color='brand' mb={1}>
        <PriceOption product={product} />
      </Typography>
    )
  }
  return null
}

ProductPrice.propTypes = {
  isSuspended: PropTypes.bool,
  isHeroCard: PropTypes.bool,
  isPurchased: PropTypes.bool,
  product: productShape
}

ProductCard.propTypes = {
  variant: PropTypes.oneOf(['hero', 'default']),
  product: productShape,
  imageSize: PropTypes.number,
  imageContain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isVisible: PropTypes.bool,
  noTrack: PropTypes.bool,
  boxSize: PropTypes.oneOf(['default', 'small']),
  showSeeMore: PropTypes.oneOf([false, true, 'link', 'button']),
  seeMoreURLPrefix: PropTypes.string,
  showAction: PropTypes.oneOf([false, true, 'embed']),
  seeMoreText: PropTypes.node,
  hideRating: PropTypes.bool,
  withTrialInfo: PropTypes.bool,
  initPurchase: PropTypes.bool
}

ProductCard.defaultProps = {
  variant: 'default',
  imageContain: ['contain', 'cover'],
  noTrack: false,
  boxSize: 'default',
  showSeeMore: true,
  seeMoreURLPrefix: '',
  showAction: true,
  hideRating: false,
  withTrialInfo: false
}
