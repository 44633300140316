import PropTypes from 'prop-types'

import { Button, Flex, Typography } from '@etvas/etvaskit'

import { ModalDialog } from '@shared/components'
import { userApi, userSessionTrack } from '@shared/funcs'
import { T } from '@shared/i18n'

const LogoutModal = ({ closeModal, ...props }) => {
  const handleLogout = () => {
    userSessionTrack.clear()
    userApi.signOut()
  }

  return (
    <ModalDialog
      alignItems='center'
      justifyContent='center'
      onDismiss={closeModal}
      width={['90%', '340px']}
      p={6}
      title={<T label='label.logout' />}
      data-testid='logout-modal'
      {...props}
    >
      <Typography mt={6} variant='labelSmall'>
        <T label='text.confirmLogout' />
      </Typography>
      <Flex mt={10}>
        <Button variant='primary' onClick={closeModal}>
          <T label='label.cancelLogout' />
        </Button>
        <Button
          data-testid='logout-confirm-button'
          variant='primary'
          onClick={handleLogout}
          ml={3}
        >
          <T label='label.logout' />
        </Button>
      </Flex>
    </ModalDialog>
  )
}

LogoutModal.propTypes = {
  closeModal: PropTypes.func
}

export default LogoutModal
