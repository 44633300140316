import { useContext } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import { Box, Chip } from '@etvas/etvaskit'

import { trialUnits } from '@shared/constants/product'
import { useFeatureGate } from '@shared/hooks'
import { I18nContext } from '@shared/i18n'
import { productShape, purchaseStatus } from '@shared/models'

export const TrialChip = ({ product, isNeutral, ...props }) => {
  const { trialPeriod, trialUnit, trialEndsAt } = product
  const isTrialChipFreeOfCharge = useFeatureGate('trial_chip_free_of_charge')
  const { translate } = useContext(I18nContext)
  const isTrialActive = !!trialEndsAt
  const isPurchased = product.purchaseStatus === purchaseStatus.Purchased

  if (!isTrialActive && !(trialPeriod && trialUnit)) {
    return null
  }

  if (isPurchased && !isTrialActive) {
    return null
  }

  const chipInfo = isTrialActive
    ? _getRemainingTrial(product)
    : isTrialChipFreeOfCharge
      ? _getCustomTrial(product)
      : _getDefaultTrial(product)

  const textContent = !Array.isArray(chipInfo)
    ? translate(chipInfo.label, chipInfo.args, chipInfo.mark)
    : chipInfo
        .map(info =>
          typeof info === 'string'
            ? info
            : translate(info.label, info.args, info.mark)
        )
        .join('')

  const chipColor = isTrialActive
    ? chipInfo.days < 1
      ? 'statusError'
      : 'statusSuccess'
    : 'etvasLight'

  return (
    <Box data-testid='trial-period' {...props}>
      <Chip isRounded color={chipColor} isNeutral={isNeutral}>
        {textContent}
      </Chip>
    </Box>
  )
}

const _getDefaultTrial = ({ trialUnit, trialPeriod }) => ({
  label: `label.trial.${trialUnits[trialUnit]}`,
  args: [trialPeriod],
  mark: 0
})

const _getCustomTrial = ({ trialUnit, trialPeriod }) => [
  {
    label: `label.${trialUnits[trialUnit]}`,
    args: [trialPeriod],
    mark: 0
  },
  ' ',
  {
    label: 'label.freeOfCharge'
  }
]

const _getRemainingTrial = ({ trialEndsAt }) => {
  const now = moment.utc().startOf('day')
  const then = moment(trialEndsAt)
  const days = then.clone().startOf('day').diff(now, 'days')

  if (days < 7) {
    return { label: 'label.remainingTrial.days', args: [days], mark: 0 }
  }

  return {
    label: 'label.remainingTrial.on',
    args: [then.local().format('ll')],
    days
  }
}

TrialChip.propTypes = {
  product: productShape,
  isNeutral: PropTypes.bool
}
